//import React from "react";
import React, { useEffect,useState } from "react";
import ReactGA from "react-ga";
import { useLocation, useHistory } from "react-router-dom";
import { Image } from "../../subcomponents/Elements";
import NumberFormat from "react-number-format";
import axios from "axios";
import {
  amplitudeBtnEvent,
  ampRabbitMQBtnEvent,
  getParam,
} from "../../../utils";
import {
  checkCreditCardNumber,
  validateCardNumber,
} from "../../../utils/payments";

export default function Credit_Juspay_Copy({
  urlparams,
  paymentsData,
  updateMultipleFormData,
  isFreeTrial,
  hungamaIdentity,
  jusPayUser,
  juspayOrderId,
  mandate
}) {
  console.log('urlparam',juspayOrderId);
  const location    = useLocation().search;
  const plan_type   = getParam(location, "plan_type");
  const [isAgree, setIsAgree] = useState(true);
  const inputBoxStyle = {
    border: '1px solid #ccc',
    padding: '5px',
    borderRadius: '7px',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    height: '38px',
    position: 'relative',
    overflow: 'hidden',
    background: 'rgb(58 57 57 / 90%)',
    border: '.4px solid rgba(255, 255, 255, .47)',
    outline:'none',
    color: '#fff',
};
  /*
    //control auto pay checkbox for users
    let isValidUser=false;
    const identity = getParam(urlparams, 'identity');
    let allowCheckConsent=[];
    */
  let isValidUser = false;
  let plan_id = getParam(urlparams, "plan_id");
  const planType = getParam(urlparams, "plan_type").toLocaleLowerCase();
  if (planType !== "event" && !/vod/.test(planType) && plan_id !== "10") {
    isValidUser = true;
  }

  const [isValidName, setValidName] = useState(false);
  const [isValidNameSp, setValidNameSp] = useState(false);
  const [isValidCardNumber, setValidCardNumber] = useState(false);
  const [isValidMonth, setValidMonth] = useState(false);
  const [isValidCvv, setValidCvv] = useState(false);
  const [freeTrialCCValid, setfreeTrialCCValid] = useState(false);
  const TRACKING_ID = `${process.env.REACT_APP_GA_CODE}`; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  const useAnalyticsEventTracker = (category = "Payment Init") => {
    const eventTracker = (action = "action init", label = "label init") => {
      ReactGA.event({ category, action, label });
    };
    return eventTracker;
  };
  const gaEventTracker = useAnalyticsEventTracker("Credit Debit Card");
      
  const validateCardNumberLoc = async (e, isFreeTrial) => {
    //console.log('cc='+paymentsData.data.planInfo.plan_name);
    gaEventTracker("pay_now", paymentsData?.data?.planInfo?.plan_name);
    var nameRegex = /^[A-Za-z\s]+$/;
    setValidName(false);
    setValidNameSp(false);
    setValidCardNumber(false);
    if (document.getElementById("cardHolderName").value === "") {
      setValidName(true);
      e.preventDefault();
      return false;
    } else if (document.getElementById("cardHolderName").value !== "") {
      if (!nameRegex.test(document.getElementById("cardHolderName").value)) {
        setValidName(false);
        setValidNameSp(true);
        e.preventDefault();
        return false;
      }
    }
    if (document.getElementById("cardNumber").value === "") {
      setValidCardNumber(true);
      setValidName(false);
      e.preventDefault();
      return false;
    }

    if (
      validateCardNumber(paymentsData?.formData?.cardNumber.replace(/ /g, ""))
    ) {
      // console.log("Valid Card Number");
      setValidName(false);
      // amplitudeBtnEvent(paymentsData?.formData?.identity, "Btn_Submit_CCDC");
      ampRabbitMQBtnEvent(
        {
          identity: paymentsData?.formData?.identity,
          product_id: paymentsData?.formData?.product_id,
          platform_id: paymentsData?.formData?.platform_id,
        },
        "Btn_Submit_CCDC"
      );
    } else {
      setValidCardNumber(true);
      setValidName(false);
      e.preventDefault();
      return false;
    }

    var result = true;
    var errMsg = "";
    var expire =`${document.getElementById("expiryDate").value}/${document.getElementById("expiryYear").value}}`;
    if (!expire.match(/(0[1-9]|1[0-2])[/][0-9]{2}/)) {
      errMsg += paymentsData?.language?.expire_date_format_error;
      result = false;
    } else {
      // get current year and month
      var d = new Date();
      var currentYear = d.getFullYear();
      var currentMonth = d.getMonth() + 1;
      // get parts of the expiration date
      var parts = expire.split("/");
      var year = parseInt(parts[1], 10) + 2000;
      var month = parseInt(parts[0], 10);
      // compare the dates
      if (
        year < currentYear ||
        (year === currentYear && month < currentMonth)
      ) {
        errMsg += paymentsData?.language?.expiry_date_passed_error;
        result = false;
      }
    }
    console.log(errMsg);
    if (!result) {
      setValidMonth(true);
      e.preventDefault();
      return false;
    } else {
      setValidMonth(false);
    }
    console.log(result);
    if (document.getElementById("cvv").value === "") {
      setValidMonth(false);
      setValidCvv(true);
      e.preventDefault();
      return false;
    }

    //free trial
    // console.log(
    //   "freeTrialStatus",
    //   isFreeTrial,
    //   "paymentsData.formData.cardCategory",
    //   paymentsData.formData.cardCategory
    // );
    if (
      isFreeTrial?.status === false &&
      isFreeTrial?.plan_id === plan_id &&
      paymentsData.formData.cardCategory === "DC"
    ) {
      // console.log("wrong DC for freetrial");
      setValidCvv(false);
      setfreeTrialCCValid(true);
      e.preventDefault();
      return false;
    } else {
      setfreeTrialCCValid(false);
    }
  };
  const checkCreditCardNumberFn = (e) => {
    const creditCardNbr = e.target.value.replace(/ /g, "");
    updateMultipleFormData({ cardNumber: e.target.value });
    // console.log(creditCardNbr.length);
    if (creditCardNbr?.length !== 0) {
      checkCreditCardNumber(creditCardNbr,planType).then((res) => {
        if (res?.data?.cardType) {
          updateMultipleFormData({
            cardType: res?.data?.cardType,
            cardCategory: res?.data?.cardCategory,
          });
        }
      });
    }
  };

  const mixpanelHit = async() =>{
    let res = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_HANGAMA_URL}/billing/pay_juspay_cc_dc_event_mixpanel.php?payment_id=1&plan_details_id=225&order_id=${juspayOrderId}&${urlparams}`,
    })
      .then((res) => {
        console.log('mixpanel api hit',res);
      })
      .catch((e) => {
        console.log(e);
      });
  }; 
  return (

            <form 
            style={{
              display:
                paymentsData?.formData?.tabToShow === "credit" ? "block" : "none",
            }}
            onSubmit={() => mixpanelHit()}
             class="form-horizontal" role="form" id="credit_card_form">
            <input type="hidden" class="redirect" value="true" />
            <input type="hidden" class="order_id" value={juspayOrderId}  />
            <input type="hidden" class="merchant_id" value="Hungama" />
            <input type="hidden" class="payment_method_type" value="CARD" />
            <input type="hidden" class="juspay_locker_save" checked="checked" />
            <input type="hidden" class="should_create_mandate" value={mandate} />
            {/* <fieldset> */}
              {/* <legend>Payment</legend> */}
              <div class="form-group input-box">
                <label class="col-sm-3 control-label" for="card-number">Card Number</label>
                <div class="input-box col-sm-9">
                  <div class="card_number_div form-control pay-input" style={inputBoxStyle} id="card-number" placeholder="xxxx-xxxx-xxxx-xxxx"></div>
                </div>
              </div>
              {/* <input type="text" name="card-number" maxlength="100" id="card-number" class="pay-input" value="" placeholder="xxxx-xxxx-xxxx-xxxx"></input> */}
              <div class="form-group input-box">
                <label class="col-sm-3 control-label" for="card-holder-name">Name on Card</label>
                <div class="input-box col-sm-9">
                  <div class="name_on_card_div form-control" style={inputBoxStyle} id="card-holder-name" placeholder="Enter Card Holder Name"></div>
                </div>
             {/* <input type="text" name="card-holder-name" maxlength="100" id="card-holder-name" class="pay-input" value="" placeholder="Enter Card Holder Name"></input> */}

              </div>
              <div class="form-group input-box">
                <label class="col-sm-3 control-label" for="card_exp_month">Expiration Date</label>
                <div class="col-sm-9">
                  <div class="row">
                    <div class="input-box col-sm-3 cc-left-box">
                      <div class="card_exp_month_div form-control col-sm-2" style={inputBoxStyle} inputmode="numeric"  id="card_exp_month" maxlength="2" placeholder="MM"></div>
                    </div>
                    <div class="input-box col-sm-3 cc-right-box">
                      <div  class="card_exp_year_div form-control col-sm-2" style={inputBoxStyle} inputmode="numeric"  id="card_exp_year" maxlength="2" placeholder="YY"></div>
                    </div>
                  </div>
                </div>
              </div>
              <br></br>
              <div class="form-group input-box">
                <label class="col-sm-3 control-label" for="cvv">Card CVV</label>
                <div class="input-box col-sm-3 security_code_div" style={inputBoxStyle} id="cvv">
                  {/* <input type="password" class="form-control pay-input col-sm-2 cc-left-box"  maxlength="3" placeholder="123"></input> */}
                </div>
                </div>
      
        {/* <div class="form-group">
      
          <div class="ccrow rightalign">
      
            <button type="submit" class="btn btn-success" id="common_pay_btn">
              Pay Now
            </button>
          </div>
        </div> */}
          <div class="form-group input-box">

<div class="col-sm-offset-3 col-sm-9 rightalign">

  <button type="submit" class="btn btn-success payment-btn" id="common_pay_btn">
    Pay Now
  </button>
</div>
</div>
        {/* </fieldset> */}
        </form>

  );
}
