import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// import ReactGA from "react-ga";
// import { useHistory } from "react-router-dom";
import { logData } from "../../../utils/services";
import { ampRabbitMQPageEvent, getParam } from "../../../utils";
export function PlengPaymentFailed({ orderData, formatAMPM, txtData, lang }) {
  const location = useLocation().search;
  const hostname = window.location.host;
  const pathname = useLocation().pathname;
  const couponcode = getParam(location, "couponcode");
  const [isLoadingOnce, setLoadingOnce] = useState(true);
  //  GA code
  // const TRACKING_ID = `${process.env.REACT_APP_GA_CODE_PWA}`; // OUR_TRACKING_ID
  // ReactGA.initialize(TRACKING_ID);
  // ReactGA.pageview(window.location.pathname);
  // let history = useHistory();
  // const goToPreviousPath=()=>{
  //     // history.goBack()
  //     if(platform_id==='1'){
  //         window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/wvclose.php`;
  //     }
  // }

  useEffect(() => {
    if (isLoadingOnce) {
      let source = "";
      if (
        orderData?.plan_type === "subscription" ||
        orderData?.plan_type === "event"
      ) {
        source = "PG Page_Subscription";
      }

      // amplitude with RabbitMQ
      ampRabbitMQPageEvent({
        identity: orderData?.identity,
        "Page Type": "PG Page_failure",
        Source: source,
        "Plan Name": orderData?.plan_name,
        "Plan Validity": orderData?.plan_valid,
        "Plan Value": orderData?.plan_amount,
        order_id: orderData?.order_id,
        "Page Language": lang ? lang : "en",
        product_id: orderData?.product_id,
        platform_id: orderData?.platform_id,
      });

      /* Frontend Logs */
      const lgData = {
        url: hostname + pathname,
        params: location,
        couponcode: couponcode,
        order_data: orderData,
        log_time: new Date().toLocaleString().replace(",", ""),
        log_type: "FRONTEND_PAYMENT_RESPONSE | Fail",
      };
      logData({ logData: JSON.stringify(lgData) }).then(() => {});
    }
    setLoadingOnce(false);
  }, [
    orderData,
    isLoadingOnce,
    lang,
    couponcode,
    hostname,
    location,
    pathname,
  ]);

  return (
    <div className="wrapper">
      <div className="main-container">
        <div className="pay-head">
          {/* <div className="pay-back">
            <img
              src={
                process.env.REACT_APP_ASSETS_URL +
                "/mobile/pleng/images/back-arrow.svg"
              }
              alt="arrow"
            />
          </div> */}
          <span>{txtData?.pay_payment_heading}</span>
        </div>
        <div className="pay-optinbox">
          <div className="pay-optinbox-inner">
            <div className="ticket-card">
              <div
                className=""
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_ASSETS_URL}'/mobile/pleng/images/cover-img.png')`,
                }}
              ></div>
              <p>
                <img
                  alt="logo-confir-img"
                  src={
                    process.env.REACT_APP_ASSETS_URL +
                    "/mobile/pleng/images/logo.svg"
                  }
                  className="logo-confir-img"
                />
              </p>
              <p>
                <img
                  alt="confir-img"
                  src={
                    process.env.REACT_APP_ASSETS_URL +
                    "/mobile/pleng/images/payment-failed.svg"
                  }
                  className="confir-img"
                />
              </p>

              <h2 className="confir-title">{txtData?.payment_failed}</h2>
              {/* <p className="confir-genre">Don’t worry your money is safe! If your money is debited from your account it will be refunded In 5-6 working days</p>	 */}
              {orderData?.payment_id === "14" && (
                <p className="confir-genre">{txtData?.pay_fail_coin_genre}</p>
              )}
              {orderData?.payment_id !== "14" && (
                <p className="confir-genre">{txtData?.pay_fail_amt_genre}</p>
              )}
            </div>

            <div class="pay-mode-box  pay-mode-bottom-p">
              <p class="p-t1">{txtData?.order_details}</p>
              <div class="order-detail">
                <div class="order-first-col">{txtData?.order_id}</div>
                <div class="order-last-col">{orderData?.order_id}</div>
              </div>
              <div class="order-detail">
                <div class="order-first-col">{txtData?.order_date_time}</div>
                <div class="order-last-col">
                  {formatAMPM(new Date(orderData?.order_date))}
                </div>
              </div>
              <div class="order-detail">
                <div class="order-first-col">{txtData?.amount}</div>
                <div class="order-last-col">
                  {orderData.plan_currency === "INR" ? "₹" : "$"}{" "}
                  {orderData?.txn_amount}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div class="footer-action"><div class="button-blue d-block">Start Exploring</div></div> */}
      <div className="footer-action">
        <a
          href={`/pleng/retry/${orderData?.order_id}/${lang}`}
          className="button-blue d-block"
        >
          {txtData?.retry}
        </a>
      </div>
    </div>
  );
}
