import axios from "axios"; 
const qs = require('qs');
const FormData = require('form-data');

export async function getJusPayCustomer(identity)
{
    /* 
      this function call pay-node api 
      if user exist based on identity it return juspay user id
    */
    const jusPayIdentity = await get_jus_pay_identity(identity); 
    console.log('Node juspay idenetity => ', jusPayIdentity);
    if(jusPayIdentity.message_code == 'GET_CUSTOMER_SUCCESS'){
      /* 
        this function return juspay customr detail based on juspay id
        juspay user id getting from get_jus_pay_identity() function
      */
      
      const getJusPayUser = await jusPayUser(jusPayIdentity.juspay_identity);
      console.log("jusPay USER => ", getJusPayUser);
      return jusPayIdentity;
    } 
    else{
      /* 
        this function fetch user from sso based on hungama id
      */
      const HungamaUser = await get_SSO_User(identity);
        if(HungamaUser.code == 200)
        {
          const createJusPayCustomer = await createJuspayCustomer(HungamaUser);
            if(createJusPayCustomer.hasOwnProperty("id") && createJusPayCustomer.status != '')
              {
                const jusPayIdentity = createJusPayCustomer.id;
                /* 
                  this function use for new customer for juspay
                */
                const createJusPayUser = create_pay_node_customer(identity, jusPayIdentity);
                console.log("Created jus pay user", createJusPayCustomer);
                var createUser = {};
                createUser['identity']        = identity;
                createUser['juspay_identity'] = createJusPayCustomer.id;
                return createUser;
              }
              else{
                console.log("Unable to create juspay user => " , createJusPayCustomer);
              }
        }
        else{
          console.log('Something went wrong => ', HungamaUser);
        }
    }
}

export async function jusPayUser(jusPayIdentity){
   let config = {
        method: 'get',
        maxBodyLength: Infinity,
        //url: 'https://api.juspay.in/customers/cth_hYfdyva1pgLQrPCR',
        url: `${process.env.REACT_APP_UAT_API_URL}/v1/juspay/`+jusPayIdentity,
        headers: { 
          /* 'x-merchantid':'hungama', 
          'Content-Type':'application/x-www-form-urlencoded', 
          'Authorization':'Basic RDM5NDQyRjcyRjE0RThCQkMyRTdFOUE0QUY3QzQ1' */
        }
      };
      try {
        const response = await axios.request(config);
        console.log(JSON.stringify(response.data));
        return response.data; 
      } catch (error) {
          console.error('Error fetching Hungama user details:', error);
          throw error; // Optionally re-throw the error if needed
      }
}

export async function get_SSO_User(hungamaID) {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://h-accounts.hungama.com/webservice/api/user.php?action=getuserdetails&user_id=' + hungamaID,
        headers: {}
    };
    var userData = {};
    try {
        const response = await axios.request(config);
        const isEmail = validateEmail(response.data.data.USER_NAME);
        //console.log('is email => ', isEmail);
        userData['code'] = response.data.code;
        userData['user_id'] = response.data.data.USER_ID;
        userData['email'] = '';
        userData['mobile'] = '9999999999';
        if(validateEmail(response.data.data.USER_NAME)){
          userData['email'] = response.data.data.USER_NAME;
        }
        else{
          userData['mobile'] = response.data.data.USER_NAME;
          userData['email'] = response.data.data.USER_ID+'@mailinator.com';
        }
        userData['username'] = response.data.data.USER_NAME;
        userData['first_name'] = response.data.data.FIRST_NAME;
        userData['last_name'] = response.data.data.LAST_NAME;
        //return response.data;
        return userData;
    } catch (error) {
        console.error('Error fetching Hungama user details:', error);
        throw error; // Optionally re-throw the error if needed
    }
}

export async function get_jus_pay_identity(hungamaID) {
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_UAT_API_URL}/v1/billing/orders/juspay_get_customer?userId=`+hungamaID,
    headers: { 
      'api-key': 'eedca4a2d4b0b360628958dd0fd210a6'
    }
  };
  var jusPayUserData = {};
  try {
    const response = await axios.request(config);
    console.log(JSON.stringify(response.data.data[0]));
    if(response.data.messageCode == "GET_CUSTOMER_SUCCESS")
    {
        jusPayUserData['identity']        = response.data.data[0].IDENTITY;
        jusPayUserData['juspay_identity'] = response.data.data[0].JUSPAY_IDENTITY;
    }
    jusPayUserData['message_code']    = response.data.messageCode;
    jusPayUserData['status_code']     = response.data.statusCode;
    return jusPayUserData; 
  } catch (error) {
      console.error('Error fetching Hungama user details:', error);
      throw error; // Optionally re-throw the error if needed
  }
}

  export async function createJuspayCustomer(userData)
  {
    let data = {
      'object_reference_id': userData.email,
      'mobile_number': userData.mobile,
      'email_address': userData.email,
      'first_name': userData.first_name,
      'last_name': userData.last_name,
      'mobile_country_code': '91' 
    };

    console.log("stringify data => ", data);
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      //url: 'https://api.juspay.in/customers',
      url: `${process.env.REACT_APP_UAT_API_URL}/v1/juspay`,
      headers: { 
        /* 'x-merchantid':'hungama', 
        'Content-Type':'application/x-www-form-urlencoded', 
        'Authorization':'Basic RDM5NDQyRjcyRjE0RThCQkMyRTdFOUE0QUY3QzQ1' */
        'Content-Type':'application/json'
      },
      data : data
    };
    try {
      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));
      return response.data; 
    } catch (error) {
        console.error('Error fetching Hungama user details:', error);
        throw error; // Optionally re-throw the error if needed
    }

  }


  export async function create_pay_node_customer(identity, jusPayIdentity)
  {
    let data = JSON.stringify({
      "identity":identity,
      "juspay_identity":jusPayIdentity
    });
    
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_UAT_API_URL}/v1/billing/orders/juspay_insert_customer`,
      headers: { 
        'Content-Type': 'application/json', 
        'api-key': 'eedca4a2d4b0b360628958dd0fd210a6'
      },
      data : data
    };
    try {
      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));
      return response.data; 
    } catch (error) {
        console.error('Error fetching Hungama user details:', error);
        throw error; // Optionally re-throw the error if needed
    }
    
}

const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

