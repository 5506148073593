import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { getParam } from "../../utils";
import Header from "../subpages/vodacomHeader";
import Sidebar from "../subpages/Plans/Sidebar";
import Footer from "../subpages/Footer";
import { plansReducer } from "../../utils/reducers";
import { defaultPlans } from "../../../constants";
import { language5 } from "../../utils/services";
import { checkOrder } from "../../utils/payments";
import { useImmerReducer } from "use-immer";
import { Failed } from "../subpages/fail/vodacomfailed";
// import { Pending } from "../subpages/fail/pending";

export function DesktopVodacomfailed() {
  const location = useLocation().search;
  const order_id = getParam(location, "order_id");
  const lang = getParam(location, "lang");
  //const product_id = getParam(location, "product_id");
  const identity = getParam(location, "identity");

  let history = useHistory();
  const [plansData, setPlansData] = useImmerReducer(plansReducer, defaultPlans);
  // console.log(plansData);
  const updateMultipleFormData = (obj) => {
    setPlansData({ type: "updateMultipleFormData", payload: obj });
  };
  useEffect(() => {
    // checkOrder(order_id).then((res) => {
    //   if (res.data) {
    //     console.log(res.data.order_data);
    //     setPlansData({
    //       type: "updateMultipleFormData",
    //       payload: { orderData: res.data.order_data },
    //     });
    //   } else {
    //     history.push("/404");
    //   }
    // });

    //language
    language5(lang).then((lan) => {
      //set default lang for lang convert
      //   setPlansData({
      //     type: "defaultlang",
      //     payload: lan?.default,
      //   });
      setPlansData({
        type: "language",
        payload: lan[lan?.secondLang ? "secondLang" : "default"],
      });
    });
  }, [lang]);
  return (
    <div id="wrapper">
      <Header
        plansData={plansData}
        updateMultipleFormData={updateMultipleFormData}
      />
      <div id="content">
        <div className="main-contentBox-wrapper">
          <Sidebar plansData={plansData} />
          <div className="main-area-container remove-add-container">
            <div className="inner-mid-area">
              <div className="pdlr-40">
                <div className="breadcrumbs-nav flex1">
                </div>
              </div>

              {/* {(plansData.formData.orderData.order_status === "5" ||
                plansData.formData.orderData.order_status === 5) && ( */}
                <Failed plansData={plansData} />
              {/* )} */}
            </div>
            <Footer lang={plansData?.language} />
          </div>
        </div>
      </div>
      {/* <!--Content Area End--> */}
    </div>
  );
}
