import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useLocation, useHistory } from "react-router-dom";
import { useImmerReducer } from "use-immer";
import axios from "axios";
// Components
import OrderSummary from "../subpages/Payments/OrderSummary";
// import Preferred from '../subpages/Payments/Preferred'
import Upi from "../subpages/Payments/Upi";
import Credit from "../subpages/Payments/Credit";
import Credit_Juspay from "../subpages/Payments/Credit_Juspay";
import Credit_Juspay_Copy from "../subpages/Payments/Credit_Juspay_Copy";
import Netbanking from "../subpages/Payments/Netbanking";
import Wallet from "../subpages/Payments/Wallet";
import Coins from "../subpages/Payments/Coins";
import Tabs from "../subpages/Payments/Tabs";
import Popup from "../subpages/Payments/verifyPopUp";
import Footer from "../subpages/Footer";

// constants
import { SUCCESS, defaultPayments } from "../../../constants";

//subcomponents
import { Image } from "../subcomponents/Elements";

//services
import { paymentsReducer } from "../../utils/reducers";
import {
  ampRabbitMQBtnEvent,
  ampRabbitMQPageEvent,
  getParam,
} from "../../utils";
import { payments } from "../../utils/payments";
import {
  verifyCouponCode,
  language,
  logData,
  checkFreeTrialStatus,
} from "../../utils/services";
import { freeTrialPlanData } from "../../../constants";
import { getJusPayCustomer, get_jus_pay_identity } from "../../utils/juspay";


export function DesktopPayment() {
  let history       = useHistory();
  const location    = useLocation().search;
  const product_id  = getParam(location, "product_id");
  const platform_id = getParam(location, "platform_id");
  const country     = getParam(location, "country");
  const plan_id     = getParam(location, "plan_id");
  const identity    = getParam(location, "identity");
  const plan_type   = getParam(location, "plan_type");
  const content_id  = getParam(location, "content_id");
  let couponcode    = getParam(location, "couponcode");
  const lang        = getParam(location, "lang");
  const urlparams   = location.replace("?", "");
  const hardware_id = getParam(location, "hardware_id");
  const upilist     = getParam(location, "upilist");
  const utm_source  = getParam(location, "utm_source");
  const originalSource = getParam(location, "source");
  const aff_id      = getParam(location, "aff_id");
  const hostname    = window.location.host;
  const pathname    = useLocation().pathname;
  const [freeTrialData, setFreeTrialData] = useState({});
  const [mandate,setMandate] = useState(false);
  const [paymentsData, setPaymentsData]   = useImmerReducer(
    paymentsReducer,
    defaultPayments
  );
  const [juspayOrderId,setjuspayOrderId] = useState("")
  const TRACKING_ID = `${process.env.REACT_APP_GA_CODE}`; // OUR_TRACKING_ID
  const [jusPayIdentity, setjusPayIdentity] = useState({});

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(window.location.pathname);
    /* console.log('identity is', identity);
    getJusPayCustomer(identity); */
  }, [TRACKING_ID]);

  /* this useEffect use for getting jsupay user detail */
  useEffect( async () => {
    const getJusPayUser = await get_jus_pay_identity(identity);
    if(getJusPayUser.message_code == 'GET_CUSTOMER_SUCCESS'){
        jusPayIdentity.juspay_identity = getJusPayUser.juspay_identity;
        jusPayIdentity.hungama_identity = getJusPayUser.identity;
        console.log("in credit page getUSER => ", getJusPayUser); 
        setjusPayIdentity({...jusPayIdentity});
    }
    else{
      const createJusPayUser = await getJusPayCustomer(identity);
      jusPayIdentity.juspay_identity = createJusPayUser.juspay_identity;
      jusPayIdentity.hungama_identity = createJusPayUser.identity;
      setjusPayIdentity({...jusPayIdentity});
      console.log("in credit page createUSER => ", createJusPayUser); 
    }
    const planDetail = await payments({plan_id,plan_type,content_id,identity,product_id,platform_id,country});
    jusPayIdentity.plan_type  = plan_type;
    jusPayIdentity.plan_id    = plan_id;
    jusPayIdentity.plan_info  = planDetail.data.planInfo;
    setjusPayIdentity({...jusPayIdentity});
    if(plan_type == "subscription")
      {
        setMandate(true)
      }
    console.log("plan detail => ", planDetail.data.planInfo); 
    console.log("in credit page setState => ", jusPayIdentity); 
    intentDataNew(planDetail.data.planInfo);
  }, []); 
  const intentDataNew = async (data) => {
    console.log('inside this function',data);
    let res = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_HANGAMA_URL}/billing/pay_juspay_cc_dc.php?payment_id=1&plan_details_id=225&type=charge&${urlparams}&coupon_code=${couponcode}&juspay_identity=${jusPayIdentity.juspay_identity}&hid=${identity}&frequency=${data.plan_name}&og_price=${data.original_price}&plan_price=${data.plan_price}`,
    })
      .then((res) => {
        console.log('final data',res?.data[0].order_id);
        setjuspayOrderId(res?.data[0].order_id)
        const response = res?.data;
        return response;
      })
      .catch((e) => {
        console.log(e);
      });
      return res;
      };
  // if (
  //   couponcode === null ||
  //   couponcode === "" ||
  //   couponcode?.length === 0 ||
  //   couponcode === undefined
  // ) {
  //   couponcode = null;
  // }
  const useAnalyticsEventTracker = (category = "Payment Init") => {
    const eventTracker = (action = "action init", label = "label init") => {
      ReactGA.event({ category, action, label });
    };
    return eventTracker;
  };

  useEffect(() => {
    setPaymentsData({
      type: "updateMultipleFormData",
      payload: {
        product_id,
        platform_id,
        utm_source,
        plan_id,
        identity,
        aff_id,
        couponcode,
      },
    });
  }, [
    setPaymentsData,
    aff_id,
    utm_source,
    product_id,
    plan_id,
    identity,
    couponcode,
    platform_id,
  ]);

  // console.log(paymentsData);
  const gaEventTracker = useAnalyticsEventTracker("Payment Gateway");

  useEffect(() => {
    //free trial
    if (freeTrialPlanData?.isActive === true) {
      checkFreeTrialStatus({ product_id, country, identity }).then((res) => {
        if (res?.status === SUCCESS) {
          console.log("freetrial success!", res);
          if (
            res?.data?.free_trial_status === false &&
            freeTrialPlanData?.country === country &&
            freeTrialPlanData?.product_id === product_id
          ) {
            let obj = { ...freeTrialPlanData, status: false };
            setFreeTrialData(obj);
          } else {
            let obj = { ...freeTrialPlanData, status: true };
            setFreeTrialData(obj);
          }
        }
      });
    } else {
      let obj = { ...freeTrialPlanData, status: true };
      setFreeTrialData(obj);
    }
  }, [country, identity, product_id]);

  useEffect(() => {
    if (Object.keys(freeTrialData)?.length > 0) {
      payments({
        plan_id,
        plan_type,
        content_id,
        identity,
        product_id,
        platform_id,
        country,
      }).then((res) => {
        if (res.status === SUCCESS) {
          //Free Trial Payment Page Code
          if (
            Number(freeTrialData?.plan_id) === Number(plan_id) &&
            freeTrialData?.status === false
          ) {
            // console.log("freetrial success payment page");
            //remove coupon for trial offer

            res.data.planInfo.plan_price = Number(freeTrialData?.plan_amt);
            res.data.payments = res?.data?.payments.filter(
              (el) =>
                el?.plan_group_type === "CC" ||
                el?.plan_group_type === "upiPayments"
            );
            console.log(
              "updated payments::",
              res?.data?.payments,
              "arr",
              res?.data?.payments.filter(
                (el) =>
                  el?.plan_group_type === "CC" ||
                  el?.plan_group_type === "upiPayments"
              )
            );
            //HANDEL COUPON FOR TRIAL OFFER
            setPaymentsData({
              type: "updateMultipleFormData",
              payload: { couponcode: "" },
            });
          }
          setPaymentsData({ type: "paymentsData", payload: res.data });
          // console.log(res.data);
          let tabShow;
          // if (res.data.featuredPayment.payment_id) {
          //     tabShow = "preferred";
          // } else
          if (res.data.payments.length > 0) {
            for (var key in res.data.payments) {
              if (res.data.payments[key].payment_name === "upiPayments") {
                tabShow = "upi";
                break;
              }
              if (res.data.payments[key].payment_name === "Credit Card") {
                tabShow = "credit";
                break;
              }
              if (
                res.data.payments[key].plan_group_type === "netBankingPayments"
              ) {
                tabShow = "netbanking";
                break;
              }
              if (res.data.payments[key].plan_group_type === "walletPayments") {
                tabShow = "wallet";
                break;
              }
              if (res.data.payments[key].plan_group_type === "COINS") {
                tabShow = "coins";
                break;
              }
            }
          }

          //console.log(tabShow);

          setPaymentsData({
            type: "updateMultipleFormData",
            payload: { tabToShow: tabShow },
          });

          if (couponcode?.length !== 0) {
            //console.log("COUPONCODE", couponcode, "urlparams:", urlparams);
            verifyCouponCode(couponcode).then((resC) => {
              if (resC.status === SUCCESS) {
                if (resC.data.coupon_details.type) {
                  setPaymentsData({
                    type: "applyCouponCode",
                    payload: {
                      data: {
                        ...res.data.planInfo,
                        plan_id: plan_id,
                      },
                      res: resC?.data,
                    },
                  });
                } else {
                  setPaymentsData({
                    type: "updateMultipleFormData",
                    payload: { couponValidStatus: true },
                  });
                }
              } else {
                // history.push("/404");
              }
            });
          }

          let pageType = "",
            source = "";
          if (plan_type === "subscription" || plan_type === "event") {
            pageType = "PG Page_Subscription";
            source = "Plan Page";
          } else if (plan_type === "live_concert") {
            pageType = "PG Page_Live Event";
            source = "N/A";
          } else {
            pageType = "PG Page_TVOD";
            source = "N/A";
          }

          const { plan_name, plan_valid, plan_price, plan_currency } =
            res.data.planInfo;
          /*
          amplitudePageEvent(identity, {
            "Page Type": pageType,
            Source: source,
            "Original Source": originalSource ? originalSource : "N/A",
            "Plan Name": plan_name,
            "Plan Validity": plan_valid,
            "Plan Value": plan_price?.toFixed(2),
            "Plan Currency": plan_currency,
            "Plan ID": plan_id,
            "Page Language": lang ? lang : "en",
          });
          */
          /* amplitude with RabbitMQ */
          ampRabbitMQPageEvent({
            identity: identity,
            "Page Type": pageType,
            Source: source,
            "Original Source": originalSource ? originalSource : "N/A",
            "Plan Name": plan_name,
            "Plan Validity": plan_valid,
            "Plan Value": plan_price?.toFixed(2),
            "Plan Currency": plan_currency,
            "Plan ID": plan_id,
            "Page Language": lang ? lang : "en",
            product_id: product_id ? product_id : "",
            platform_id: platform_id ? platform_id : "",
          });
        } else {
          // handleHistory()
        }
      });

      language(lang).then((ln) => {
        setPaymentsData({
          type: "language",
          payload: ln[ln.secondLang ? "secondLang" : "default"],
        });

        //set default lang
        setPaymentsData({
          type: "defaultlang",
          payload: ln?.default,
        });
      });

      const lgData = {
        url: hostname + pathname,
        params: location,
        couponcode: couponcode,
        plan_id: plan_id,
        product_id: product_id,
        platform_id: platform_id,
        country: country,
        identity: identity,
        plan_type: plan_type,
        hardware_id: hardware_id,
      };
      logData(lgData);
    }
  }, [
    utm_source,
    originalSource,
    hardware_id,
    hostname,
    location,
    pathname,
    lang,
    couponcode,
    setPaymentsData,
    plan_id,
    plan_type,
    content_id,
    identity,
    product_id,
    platform_id,
    country,
    history,
    freeTrialData,
  ]);

  const tabToShow = (targetVal) => {
    setPaymentsData({
      type: "updateMultipleFormData",
      payload: { tabToShow: targetVal },
    });
    //console.log(paymentsData.data.planInfo.plan_name);
    gaEventTracker(targetVal, paymentsData.data.planInfo.plan_name);
  };

  const updateMultipleFormData = (obj) => {
    setPaymentsData({ type: "updateMultipleFormData", payload: obj });
  };

  const applyCouponCode = (obj) => {
    setPaymentsData({
      type: "applyCouponCode",
      payload: {
        data: { ...paymentsData.data.planInfo, plan_id: plan_id, urlparams },
        res: obj,
      },
    });
  };

  // useEffect(() => {
  //   console.log("freeTrial from payment:::", freeTrialData);
  // }, [freeTrialData]);

  return (
    <>
      <Popup
        urlparams={urlparams}
        updateMultipleFormData={updateMultipleFormData}
        paymentsData={paymentsData}
      />
      <div className="wrapper">
        <div className="p-header">
          <div className="common-padding">
            <span
              onClick={(e) => {
                // amplitudeBtnEvent(identity, "Btn_Back_PG");
                ampRabbitMQBtnEvent(
                  {
                    identity: identity,
                    product_id: product_id,
                    platform_id: platform_id,
                  },
                  "Btn_Back_PG"
                );
                history.goBack();
              }}
              className="backicon"
            >
              <Image src="back-arrow.svg" />
            </span>
            <a href={process.env.REACT_APP_HUNGAMA_WEB_URL} className="logo">
              <Image src="logo.svg" />
            </a>
          </div>
        </div>
        <div className="p-content">
          <div className="common-padding">
            <div className="upi-payment-wrap flex1">
              <div className="p-contentbox-left">
                <div className="p-titlebox">
                  <h1 className="p-title">
                    {paymentsData?.language?.pay_payment_method}
                  </h1>
                  <p className="p-securename">
                    <Image src="lock-icon.svg" className="lock-icon" />
                    {paymentsData?.language?.pay_secure_text}
                  </p>
                </div>
                <div className="upi-payment-box flex1">
                  <div className="upi-tabs-box">
                    <Tabs
                      paymentsData={paymentsData}
                      tabToShow={tabToShow}
                      isFreeTrial={freeTrialData}
                      plan_id={plan_id}
                    />
                  </div>
                  <div className="upi-content-box">
                    {/* <Preferred urlparams={urlparams} updateMultipleFormData={updateMultipleFormData} paymentsData={paymentsData} /> */}
                    <Upi
                      upilist={upilist}
                      urlparams={urlparams}
                      updateMultipleFormData={updateMultipleFormData}
                      paymentsData={paymentsData}
                      isFreeTrial={freeTrialData}
                      plan_id={plan_id}
                      hungamaIdentity={identity}
                      jusPayUser={jusPayIdentity}
                    />
                    <Credit_Juspay_Copy
                      urlparams={urlparams}
                      updateMultipleFormData={updateMultipleFormData}
                      paymentsData={paymentsData}
                      isFreeTrial={freeTrialData}
                      hungamaIdentity={identity}
                      jusPayUser={jusPayIdentity}
                      juspayOrderId={juspayOrderId}
                      mandate={mandate}
                    />
                    <Netbanking
                      urlparams={urlparams}
                      updateMultipleFormData={updateMultipleFormData}
                      paymentsData={paymentsData}
                      hungamaIdentity={identity}
                      jusPayUser={jusPayIdentity}
                    />
                    <Wallet
                      urlparams={urlparams}
                      updateMultipleFormData={updateMultipleFormData}
                      paymentsData={paymentsData}
                      hungamaIdentity={identity}
                      jusPayUser={jusPayIdentity}
                    />
                    <Coins
                      urlparams={urlparams}
                      updateMultipleFormData={updateMultipleFormData}
                      paymentsData={paymentsData}
                    />
                  </div>
                </div>
              </div>
              <OrderSummary
                applyCouponCode={applyCouponCode}
                updateMultipleFormData={updateMultipleFormData}
                paymentsData={paymentsData}
                isFreeTrial={freeTrialData}
              />
            </div>
          </div>
        </div>
        {/* <!--Footer Section Start--> */}
        <Footer lang={paymentsData?.language} />
      </div>

     
    </>
  );
}
