import React from "react";
import ReactGA from "react-ga";
import { netBankArray } from "../../../../constants";
import { amplitudeBtnEvent, ampRabbitMQBtnEvent } from "../../../utils";

export default function Netbanking({
  urlparams,
  updateMultipleFormData,
  paymentsData,
  hungamaIdentity,
  jusPayUser,
}) {
  const TRACKING_ID = `${process.env.REACT_APP_GA_CODE}`; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  const useAnalyticsEventTracker = (category = "Payment Init") => {
    const eventTracker = (action = "action init", label = "label init") => {
      ReactGA.event({ category, action, label });
    };
    return eventTracker;
  };
  const gaEventTracker = useAnalyticsEventTracker("Net Banking");

  const payUsingNetbanking = (data) => {
    // amplitudeBtnEvent(paymentsData?.formData?.identity, "Btn_Submit_NB");
    ampRabbitMQBtnEvent(
      {
        identity: paymentsData?.formData?.identity,
        product_id: paymentsData?.formData?.product_id,
        platform_id: paymentsData?.formData?.platform_id,
      },
      "Btn_Submit_NB"
    );
    gaEventTracker(data?.payment_name, paymentsData?.data?.planInfo?.plan_name);
    // amplitudeBtnEvent(identity, "Btn_Submit_NB")
    /* const url = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?payment_id=${data?.payment_id}&plan_details_id=${data?.plan_details_id}&pg=NB&bankcode=${data?.bank_code}&type=charge&${urlparams}&coupon_code=${paymentsData?.formData?.couponcode}`; */
    const url = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?payment_id=${data?.payment_id}&plan_details_id=${data?.plan_details_id}&pg=NB&bankcode=${data?.bank_code}&type=charge&${urlparams}&coupon_code=${paymentsData?.formData?.couponcode}&juspay_identity=${jusPayUser.juspay_identity}&hid=${hungamaIdentity}&frequency=${jusPayUser?.plan_info?.plan_name}&og_price=${jusPayUser?.plan_info?.original_price}&plan_price=${jusPayUser?.plan_info?.plan_price}`;
    console.log('netbanking urls',url);
    window.location.href = url;
  };

  const payUsingOtherNetbanking = (bank_code) => {
    ampRabbitMQBtnEvent(
      {
        identity: paymentsData?.formData?.identity,
        product_id: paymentsData?.formData?.product_id,
        platform_id: paymentsData?.formData?.platform_id,
      },
      "Btn_OtherBanks_NB"
    );
    // amplitudeBtnEvent(paymentsData?.formData?.identity, "Btn_OtherBanks_NB");
    // console.log(paymentsData.data.netBankingPayments);
    const { payment_id, plan_details_id } =
      paymentsData?.data?.netBankingPayments[0];
    /* const url = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?payment_id=${payment_id}&plan_details_id=${plan_details_id}&pg=NB&bankcode=${bank_code}&type=charge&${urlparams}&coupon_code=${paymentsData?.formData?.couponcode}`; */
    const url = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?payment_id=${payment_id}&plan_details_id=${plan_details_id}&pg=NB&bankcode=${bank_code}&type=charge&${urlparams}&coupon_code=${paymentsData?.formData?.couponcode}&juspay_identity=${jusPayUser.juspay_identity}&hid=${hungamaIdentity}&frequency=${jusPayUser?.plan_info?.plan_name}&og_price=${jusPayUser?.plan_info?.original_price}&plan_price=${jusPayUser?.plan_info?.plan_price}`;
    console.log('netbanking url =>',url);
    window.location.href = url;
  };

  return (
    <div
      id="netbanking"
      className="tabcontent"
      style={{
        display:
          paymentsData?.formData?.tabToShow === "netbanking" ? "block" : "none",
      }}
    >
      {/*<div className="search-input-box">
                <a href="!#" className="search-icon"><Image src="search-icon.svg" /></a>
                <input type="txt" placeholder="All Bank" className="search-input" />
            </div>
            */}
      <div className="bank-listing-box">
        {paymentsData?.data?.netBankingPayments?.length > 0 &&
          paymentsData?.data?.netBankingPayments
            // ?.sort((a, b) => parseFloat(a.display_order) - parseFloat(b.display_order))
            .map((data, i) => (
              <div
                className="bank-row-box"
                key={i}
                onClick={(e) => payUsingNetbanking(data)}
              >
                <div className="bank-iconbox">
                  <span>
                    <img
                      src={data?.payment_logo}
                      alt={data?.payment_name}
                      className="bank-icon"
                    />
                  </span>
                </div>
                <div className="bank-namebox">
                  <span>{data?.payment_name}</span>
                </div>
              </div>
            ))}
      </div>
      <div className="view-all-box" id="viewallbox">
        <span
          onClick={(e) =>
            updateMultipleFormData({
              showallbank: !paymentsData?.formData?.showallbank,
            })
          }
          className="view-all-bank"
        >
          {/* Other banks */}
          {paymentsData?.language?.pay_nb_other_banks}
        </span>
      </div>
      <div
        className="bank-listing-box"
        id="allbank"
        style={{
          display: paymentsData?.formData?.showallbank ? "block" : "none",
        }}
      >
        {netBankArray.map((data, i) => (
          <div
            className="bank-row-box"
            key={i}
            onClick={(e) => payUsingOtherNetbanking(data?.bankcode)}
          >
            <div className="bank-iconbox">
              {/*<span><Image src="airtel-bank.svg" className="bank-icon" /></span> */}

              <span>
                <img
                  src={data?.bankLogo}
                  alt={data?.bankName}
                  className="bank-icon"
                />
              </span>
            </div>
            <div className="bank-namebox">
              <span>{data?.bankName}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
