/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import ReactGA from "react-ga";

import { Image } from "../../subcomponents/Elements";
import { verifyUpi, payUpi } from "../../../utils/payments";
import {
  ampRabbitMQBtnEvent,
  ampRabbitMQPopUpEvent,
  getUpiType,
  payNow,
  getParam,
} from "../../../utils";
import { SUCCESS } from "../../../../constants";
import { checkUpiPayment } from "../../../utils";
export default function Upi({
  upilist,
  urlparams,
  updateMultipleFormData,
  paymentsData,
  isFreeTrial,
  plan_id,
  hungamaIdentity,
  jusPayUser,
}) {
  const [isAgree, setIsAgree] = useState(true);
  /* //control auto pay checkbox for users
    const identity = getParam(urlparams, 'identity');
    console.log('identity',typeof identity,identity,'plan_type',typeof planType,planType)
    let allowCheckConsent=[];
    if(allowCheckConsent.includes(identity) && planType!=="event" && !/vod/.test(planType) ){
        isValidUser=true;
    }
    console.log('isValidUser',isValidUser)
    */
  let isValidUser = false;
  const planType = getParam(urlparams, "plan_type").toLocaleLowerCase();
  if (planType !== "event" && !/vod/.test(planType) && plan_id !== "10") {
    isValidUser = true;
  }

  const TRACKING_ID = `${process.env.REACT_APP_GA_CODE}`; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  const useAnalyticsEventTracker = (category = "Payment Init") => {
    const eventTracker = (action = "action init", label = "label init") => {
      ReactGA.event({ category, action, label });
    };
    return eventTracker;
  };
  const gaEventTracker = useAnalyticsEventTracker("UPI Payment");
  //console.log(paymentsData.data.planInfo.plan_name,'verify_and_pay');

  const verifyUpiId = () => {
    gaEventTracker("verify_and_pay", paymentsData.data.planInfo.plan_name);
    // amplitudeBtnEvent(paymentsData.formData.identity, "Btn_Verify_UPI");
    ampRabbitMQBtnEvent(
      {
        identity: paymentsData?.formData?.identity,
        product_id: paymentsData?.formData?.product_id,
        platform_id: paymentsData?.formData?.platform_id,
      },
      "Btn_Verify_UPI"
    );

    const { product_id, plan_id, upiId, couponcode } = paymentsData.formData;
    const { payment_id } = paymentsData.data.upiPayments[0];
    updateMultipleFormData({
      enableUpiError: false,
    });
    verifyUpi({ product_id, plan_id, upiId, payment_id, couponcode }).then(
      (res) => {
        // console.log(res);
        if (res.status === SUCCESS) {
          console.log(res.data);
          if (res.data.isVPAValid) {
            const { upiType, upiName } = getUpiType(res.data.vpa);

            updateMultipleFormData({
              IsUpiValid: true,
              upiType: upiType,
              upiName: upiName,
              verifyPayPopUp: false,
            });

            const { plan_name, plan_valid, plan_price, plan_currency } =
              paymentsData.data.productInfo;

            ampRabbitMQPopUpEvent({
              identity: paymentsData.formData.identity,
              Source: paymentsData.formData.utm_source,
              "Plan Name": plan_name,
              "Plan Validity": plan_valid,
              "Plan Value": plan_price,
              "Plan Currency": plan_currency,
              "Plan ID": paymentsData.formData.plan_id,
              "Affiliate ID": paymentsData.formData.aff_id,
              product_id: paymentsData.formData.product_id,
              platform_id: paymentsData.formData.platform_id,
              Action: "UPI_Confirm",
            });

            //call upi pay with timer
            verifyPayNow();
          } else {
            updateMultipleFormData({
              IsUpiValid: false,
              enableUpiError: true,
            });
          }
        } else {
          // history.push("/404");
        }
      }
    );
  };

  //call timer for upi pay now
  const verifyPayNow = () => {
    let data = {};
    if (paymentsData.data?.upiPayments[0]) {
      const { plan_details_id, payment_id, upi_type } =
        paymentsData.data?.upiPayments[0];
      data.payment_id = payment_id;
      data.plan_details_id = plan_details_id;
      data.upi_type = upi_type;
    }
    urlparams += `product_id=${paymentsData.formData.product_id}&type=upi_collect&vpa=${paymentsData.formData.upiId}`;

    // amplitudeBtnEvent(paymentsData.formData.identity, "Btn_Submit_UPI");
    ampRabbitMQBtnEvent(
      {
        identity: paymentsData.formData.identity,
        product_id: paymentsData.formData.product_id,
        platform_id: paymentsData.formData.platform_id,
      },
      "Btn_Submit_UPI"
    );
    const { plan_name, plan_valid, plan_price, plan_currency } =
      paymentsData.data.productInfo;

    ampRabbitMQPopUpEvent({
      identity: paymentsData.formData.identity,
      Source: paymentsData.formData.utm_source,
      "Plan Name": plan_name,
      "Plan Validity": plan_valid,
      "Plan Value": plan_price,
      "Plan Currency": plan_currency,
      "Plan ID": paymentsData.formData.plan_id,
      "Affiliate ID": paymentsData.formData.aff_id,
      product_id: paymentsData.formData.product_id,
      platform_id: paymentsData.formData.platform_id,
      Action: "UPI_Timer",
    });

    const upitype = data.upi_type;
    const pg = "UPI";
    const bankcode = "UPI";

    payUpi(
      upitype,
      data,
      pg,
      bankcode,
      urlparams,
      paymentsData.formData.couponcode,
      hungamaIdentity,
      jusPayUser,
    ).then((res) => {
      console.log(res);
      const { status } = res.data;
      if (status === SUCCESS) {
        if (res.data.result.txnid !== " ") {
          setTimeout(() => {
            checkUpiPayment(
              false,
              data.payment_id,
              data.plan_details_id,
              res.data.result.txnid,
              paymentsData
            );
          }, 2000);
          updateMultipleFormData({
            payPopUp: true,
            isTimer: true,
            verifyPayPopUp: false,
            order_id: res.data.result.txnid,
          });
        }
      } else {
        // history.push("/404");
      }
    });
  };

  return (
    <div
      id="upi"
      className="tabcontent"
      style={{
        display: paymentsData?.formData?.tabToShow === "upi" ? "block" : "none",
      }}
    >
      <div className="tab-content-box">
        <p className="tab-content-tittle">
          {paymentsData?.language?.pay_enter_upi_id}
        </p>
        <div className="verify-box flex1">
          <div className="pay-input-box">
            <input
              type="txt"
              onChange={(e) =>
                updateMultipleFormData({ upiId: e.target.value })
              }
              placeholder={paymentsData?.language?.pay_upi_placeholder_web}
              className="pay-input"
            />
          </div>
          <button
            disabled={isAgree === false ? true : false}
            onClick={(e) => verifyUpiId()}
            className="payment-btn"
          >
            {paymentsData?.language?.pay_verify_pay}
          </button>
        </div>
        {paymentsData.formData.enableUpiError && (
          <span style={{ color: "red", fontWeight: "bold" }}>
            {paymentsData?.language?.pay_invalid_upi}
          </span>
        )}
        <div className="pay-steps-box">
          <div className="step-number-box flex2">
            <div className="step-number">
              {paymentsData?.language?.upi_step1}
            </div>
            <div className="step-number">
              {paymentsData?.language?.upi_step2}
            </div>
            <div className="step-number">
              {paymentsData?.language?.upi_step3}
            </div>
          </div>
          <div className="step-divider">
            <Image src="divider-line1.png" className="divider-line" />
          </div>
          <div className="step-content-box flex2">
            <div className="step-content">
              {paymentsData?.language?.upi_step_content1}
              <br />
              {paymentsData?.language?.upi_step_content2}
            </div>
            <div className="step-content">
              {paymentsData?.language?.upi_step_content3}
              <br />
              {paymentsData?.language?.upi_step_content4}
            </div>
            <div className="step-content">
              {paymentsData?.language?.upi_step_content5}
              <br />
              {paymentsData?.language?.upi_step_content6}
            </div>
          </div>
        </div>
        <div className="payment-option-box">
          <ul className="payment-option-list">
            {paymentsData?.data?.upiPayments
              // ?.sort((a, b) => parseFloat(a.display_order) - parseFloat(b.display_order))
              ?.map(
                (data, i) =>
                  upilist &&
                  upilist?.includes(data?.payment_name?.toLowerCase()) && (
                    <li
                      key={i}
                      onClick={(e) => payNow(data, paymentsData, urlparams)}
                    >
                      <span key={i}>
                        <img
                          src={data?.payment_logo}
                          alt={data?.payment_name}
                          className="payment-icon"
                        />
                      </span>
                    </li>
                  )
              )}
            <li>
              <a style={{ cursor: "default" }}>
                <Image src="paytm.svg" className="payment-icon" />
              </a>
            </li>
            <li>
              <a onClick="showphonepepop()" style={{ cursor: "default" }}>
                <Image src="phonepay.svg" className="payment-icon" />
              </a>
            </li>
            <li>
              <a style={{ cursor: "default" }}>
                <Image src="bim.svg" className="payment-icon" />
              </a>
            </li>
            <li>
              <a style={{ cursor: "default" }}>
                <Image src="amazon-pay.svg" className="payment-icon" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* <div className="tab-content-box">
        <div className="scan-pay-box">
          <div className="scan-step-box">
            <p className="tab-content-tittle">Scan & Pay</p>
            <ul className="scan-steps">
              <li>Step 1. Open any “UPI App”</li>
              <li>Step 2. Click on “Scan” icon</li>
              <li>Step 3. Scan given “UPI QR Code”</li>
              <li>Step 4. Complete “Payment”</li>
            </ul>
          </div>
          <div className="scan-code-box">
            <Image src="qr-code.webp" className="qr-code" />
          </div>
        </div>
      </div> */}
      {isValidUser === true ? (
        <div className="agree-row">
          <label>
            <input
              onChange={(e) => {
                if (!e.target.checked) {
                  setIsAgree(false);
                } else {
                  setIsAgree(true);
                }
              }}
              type="checkbox"
              name="checkbox"
              value="value"
              checked={isAgree}
            />
            {/* I hereby agree and authorize Hungama to debit my account and setup
            standing instruction. */}
            {paymentsData?.language?.pay_consent}
          </label>
          {isAgree === false && (
            <p className="error-msg">
              {paymentsData?.language?.pay_consent_error}
            </p>
          )}
          {/*Free Trial*/}
          {isFreeTrial?.status === false &&
            isFreeTrial?.plan_id === plan_id &&
            isFreeTrial?.freeTrialPaymentPageText?.map((data, i) => (
              <div
                style={{
                  marginTop: "14px",
                  marginLeft: "29px",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                <p key={i}>{data}</p>
              </div>
            ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
