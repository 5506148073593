import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { Image } from "../../subcomponents/Elements";
import { useLocation } from "react-router-dom";
import {
  amplitudePageEvent,
  ampRabbitMQPageEvent,
  ampRabbitMQFailurePageEvent,
  getParam,
} from "../../../utils";
import { logData } from "../../../utils/services";

export function Failed({ plansData }) {
  const location = useLocation().search;
  const order_id = getParam(location, "order_id");
  const lang = getParam(location, "lang");
  const hostname = window.location.host;
  const pathname = useLocation().pathname;
  const couponcode = getParam(location, "couponcode");
  let submissionPlanName = "";
  if (plansData.formData.orderData.plan_name === "VALUE PACK") {
    submissionPlanName = "VALUE PACK";
  } else {
    submissionPlanName = "Hungama Gold";
  }
  // let refund_msg_currency = "Don’t worry your money is safe!<br />If your money is debited from your account it will be refunded In 5-6 working days";
  // let refund_msg_coins = "Don’t worry your coins is safe!<br />If your coins is debited from your account it will be refunded In 5-6 working days";
  // GA Code implementation
  const TRACKING_ID = `${process.env.REACT_APP_GA_CODE}`; // OUR_TRACKING_ID
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(window.location.pathname);
  }, [TRACKING_ID]);

  const useAnalyticsEventTracker = (category = "Payment Init") => {
    const eventTracker = (action = "action init", label = "label init") => {
      ReactGA.event({ category, action, label });
    };
    return eventTracker;
  };
  const trackGaEvent = () => {
    //console.log('here:'+mname);
    gaEventTracker("retry", "payment_failed");
  };
  const gaEventTracker = useAnalyticsEventTracker("Post Payment Screens");

  const formatAMPM = (date) => {
    // console.log(date.toUTCString(),'date');
    var dateString = date;
    dateString = new Date(dateString).toUTCString();
    dateString = dateString.split(" ").slice(1, 4).join(" ");
    var dateStringArr = dateString.split(" ");
    //console.log(dateStringArr)
    // console.log(dateStringArr[0]+', '+dateStringArr[1]+' '+dateStringArr[2]);
    var mdDate =
      dateStringArr[0] + ", " + dateStringArr[1] + " " + dateStringArr[2];
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes.toString().padStart(2, "0");
    let strTime = hours + ":" + minutes + " " + ampm;
    return mdDate + " | " + strTime;
  };

  //amplitude

  useEffect(() => {
    const {
      plan_name,
      plan_valid,
      plan_amount,
      order_id,
      identity,
      plan_type,
    } = plansData?.formData?.orderData;

    let source = "";
    if (plan_type === "subscription" || plan_type === "event") {
      source = "PG Page_Subscription";
    } else if (plan_type === "live_concert") {
      source = "PG Page_Live Event";
    } else {
      source = "PG Page_TVOD";
    }
    /*
    amplitudePageEvent(identity, {
      "Page Type": "PG Page_failure ",
      Source: source,
      "Plan Name": plan_name,
      "Plan Validity": plan_valid,
      "Plan Value": plan_amount,
      order_id: order_id,
      "Page Language": lang ? lang : "en",
    });
    */
    /* amplitude with RabbitMQ */
    ampRabbitMQFailurePageEvent({
      identity: identity,
      "Page Type": "PG Page_failure ",
      Source: source,
      "Plan Name": plan_name,
      "Plan Validity": plan_valid,
      "Plan Value": plan_amount,
      order_id: order_id,
      "Page Language": lang ? lang : "en",
      product_id: plansData?.formData?.orderData?.product_id,
      platform_id: plansData?.formData?.orderData?.platform_id,
    });

    /* Frontend Logs */
    const lgData = {
      url: hostname + pathname,
      params: location,
      couponcode: couponcode,
      order_data: plansData?.formData?.orderData,
      log_time: new Date().toLocaleString().replace(",", ""),
      log_type: "FRONTEND_PAYMENT_RESPONSE | Fail",
    };
    logData({ logData: JSON.stringify(lgData) }).then(() => {});
  }, [plansData, lang, couponcode, hostname, pathname, location]);

  return (
    <div className="pdlr-40 mb-20 d-flex">
      <div className="left-box">
        <div className="box-content blur-box">
          <div className="box-content-inner">
            {/*  <div className="text-center w-100">
                            <Image src="hungama-gold.png" className="hungama-gold" alt="" />
                        </div>
                    */}
            <Image src="failed.svg" className="success-icon" alt="" />
            <h3 className="title">{plansData?.language?.pay_web_fail_text1}</h3>

            {plansData?.formData?.orderData?.payment_id === "14" && (
              <p className="info">
                {/* Don’t worry your coins are safe! If your coins are debited from
                your account it will be refunded In 5-6 working days */}
                {plansData?.language?.pay_web_fail_text9_coin}
              </p>
            )}
            {plansData?.formData?.orderData?.payment_id !== "14" && (
              <p className="info">
                {/* Don’t worry your money is safe!
                If your money is debited from your account it will be refunded
                In 5-6 working days */}
                {plansData?.language?.pay_web_fail_text2}
              </p>
            )}

            <div className="mt-30 w-100">
              <a
                href={`retry/${order_id}/${lang}`}
                onClick={(e) => trackGaEvent()}
                className="button-blue button-padding-80"
              >
                {plansData?.language?.pay_web_fail_text3}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="right-box">
        <div className="box-content blur-box">
          <div className="box-content-inner">
            <p className="p-t1">{plansData?.language?.pay_web_fail_text4}</p>
            <div className="order-detail">
              <div className="order-first-col">
                {plansData?.formData?.orderData?.content_details?.plan_name
                  ? `${plansData?.language?.pay_web_fail_text10_content}:`
                  : plansData?.language?.pay_web_fail_text5}
              </div>
              <div className="order-last-col">
                {plansData?.formData?.orderData?.content_details?.plan_name
                  ? plansData?.formData?.orderData.content_details?.plan_name
                  : submissionPlanName}
              </div>
            </div>

            {plansData.formData.orderData.payment_id !== "14" && (
              <div className="order-detail">
                <div className="order-first-col">
                  {plansData?.language?.pay_web_fail_text6}:
                </div>
                <div className="order-last-col">
                  <span className="text-white">
                    {plansData.formData.orderData.plan_currency_symbol}{" "}
                    {plansData.formData.orderData.txn_amount}
                  </span>
                </div>
              </div>
            )}

            <div className="order-detail" id="rowp-3">
              <div className="order-first-col">
                {plansData?.language?.pay_web_fail_text7}:
              </div>
              <div className="order-last-col">
                {formatAMPM(new Date(plansData.formData.orderData.order_date))}
              </div>
            </div>
            <div className="order-detail" id="rowp-4">
              <div className="order-first-col">
                {plansData?.language?.pay_web_fail_text8}
              </div>
              <div className="order-last-col">
                {plansData.formData.orderData.order_id}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
