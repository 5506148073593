//import React from "react";
import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";
import { Image } from "../../subcomponents/Elements";
import { amplitudeBtnEvent, ampRabbitMQBtnEvent } from "../../../utils";
import axios from "axios";

export default function Wallet({ 
  urlparams, 
  paymentsData, 
  hungamaIdentity,
  jusPayUser,
}) {
  const [setUserBalance, setBalance] = useState("");
  const [setLinkBalance, linkBalance] = useState("");
  const TRACKING_ID = `${process.env.REACT_APP_GA_CODE}`; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  const useAnalyticsEventTracker = (category = "Payment Init") => {
    const eventTracker = (action = "action init", label = "label init") => {
      ReactGA.event({ category, action, label });
    };
    return eventTracker;
  };
  const gaEventTracker = useAnalyticsEventTracker("Wallet and More");

  const payUsingWallet = (data) => {
    gaEventTracker(data?.payment_name, paymentsData?.data?.planInfo?.plan_name);
    //console.log(data.payment_name+','+paymentsData.data.planInfo.plan_name);
    // amplitudeBtnEvent(paymentsData?.formData?.identity, "Btn_Submit_Wallet");
    ampRabbitMQBtnEvent(
      {
        identity: paymentsData.formData.identity,
        product_id: paymentsData.formData.product_id,
        platform_id: paymentsData.formData.platform_id,
      },
      "Btn_Submit_Wallet"
    );
    const { payment_id, plan_details_id } = data;
    const walletPayURL = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?payment_id=${payment_id}&plan_details_id=${plan_details_id}&${urlparams}&coupon_code=${paymentsData?.formData?.couponcode}&juspay_identity=${jusPayUser.juspay_identity}&hid=${hungamaIdentity}&frequency=${jusPayUser?.plan_info?.plan_name}&og_price=${jusPayUser?.plan_info?.original_price}&plan_price=${jusPayUser?.plan_info?.plan_price}`
    console.log('wallet url =======> ',walletPayURL)
    /* window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?payment_id=${payment_id}&plan_details_id=${plan_details_id}&${urlparams}&coupon_code=${paymentsData?.formData?.couponcode}`; */
    window.location.href = walletPayURL;
  };

  const getAmazonBalance = (identity) => {
    const getBalanceAPI = `${process.env.REACT_APP_HANGAMA_URL}/billing/api.php?payment_id=9&rtype=get_user_balance&identity=${identity}`;
    //console.log("getBalanceAPI:: "+getBalanceAPI);
    axios
      .get(getBalanceAPI)
      .then((res) => {
        let userBalance = "";
        let userLinkBalance = "";
        userBalance = res?.data?.total;
        // console.log("AXIOS RES::: "+userBalance);
        if ((userBalance && userBalance !== "") || null || undefined) {
          userBalance = " ₹" + userBalance;
        } else {
          userLinkBalance = "Link";
        }
        setBalance(userBalance);
        linkBalance(userLinkBalance);
      })
      .catch((err) => {
        console.log(err);
      });
    // console.log(formData);
  };

  useEffect(() => {
    // Update the document title using the browser API
    // getAmazonBalance(paymentsData?.formData?.identity);
    //console.log("setUserBalance :: "+setUserBalance);
  });

  return (
    <div
      id="wallet"
      className="tabcontent"
      style={{
        display:
          paymentsData?.formData?.tabToShow === "wallet" ? "block" : "none",
      }}
    >
      <p className="cc-tittle1">
        {paymentsData?.language?.pay_wallet_page_text}
      </p>
      <div className="wallet-list-box">
        {paymentsData?.data?.walletPayments?.length > 0 &&
          paymentsData?.data?.walletPayments
            // ?.sort((a, b) => parseFloat(a.display_order) - parseFloat(b.display_order))
            .map((data, i) => (
              <div
                className="wallet-row-box"
                key={i}
                onClick={(e) => payUsingWallet(data)}
              >
                <div className="wallet-iconbox">
                  <span>
                    <img
                      style={{
                        borderRadius: `${data?.payment_id === 8 ? "50%" : ""}`,
                      }}
                      alt={data?.payment_name}
                      src={data?.payment_logo}
                      className="wallet-icon"
                    />
                  </span>
                </div>
                <div className="wallet-namebox">
                  <span>{data?.payment_name}</span>

                  {data?.payment_name === "Amazon Pay" && (
                    <span>&nbsp;{setUserBalance} </span>
                  )}
                </div>
                <span className="arrow-icon">
                  {data?.payment_name === "Amazon Pay" && (
                    <span className="mr5"> {setLinkBalance}</span>
                  )}

                  <Image src="next-arrow.svg" />
                </span>
              </div>
            ))}
      </div>
    </div>
  );
}
