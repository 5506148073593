import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
// import { amplitudePageEvent } from "../../utils";
import { language5 } from "../../utils/services";
import { Paymentfailed } from "./vodacomfailed";
// import { Paymentpending } from "./paymentpending";

export function VidacomPaymentFailed() {
  const [isLoading, setLoading] = useState(true);
  const [txtData, setTextData] = useState({});
  let location = useLocation().search;
  const order_id = new URLSearchParams(location).get("order_id");
  const lang = new URLSearchParams(location).get("lang");
  const product_id = new URLSearchParams(location).get("product_id");
  const platform_id = new URLSearchParams(location).get("platform_id");
  const [orderData, setOrderData] = useState([]);
  const identity = new URLSearchParams(location).get("identity");
  // const callback_url = new URLSearchParams(location).get('callback_url');

  const formatAMPM = (date) => {
    // console.log(date.toUTCString(),'date');
    var dateString = date;
    dateString = new Date(dateString).toUTCString();
    dateString = dateString.split(" ").slice(1, 4).join(" ");
    var dateStringArr = dateString.split(" ");
    //console.log(dateStringArr)
    // console.log(dateStringArr[0]+', '+dateStringArr[1]+' '+dateStringArr[2]);
    var mdDate =
      dateStringArr[0] + ", " + dateStringArr[1] + " " + dateStringArr[2];
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes.toString().padStart(2, "0");
    let strTime = hours + ":" + minutes + " " + ampm;
    return mdDate + " | " + strTime;
  };

  useEffect(() => {
      //language
      language5(lang).then((res) => {
        //set Lang conditionally
        // setTextData(res);
        // setDefaultTextData(res?.default);
        setTextData(res?.secondLang ? res?.secondLang : res?.default);
      });
      }, [lang]);
  //   console.log("MobilePaymentFailed orderData: ", orderData);
  return (
    <>
          <Paymentfailed
            txtData={txtData}
            lang={lang}
            platform_id={platform_id}
            orderData={orderData}
            formatAMPM={formatAMPM}
          />
    </>
  );
}
