import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Tvodsuccess from "../transactionsucess/tvodsuccess";
import Subscriptionsuccess from "../transactionsucess/vodacomsuccess";
import { language5 } from "../../utils/services";
import ReactGA from "react-ga";

export function Vodacomsuccess() {
  //  GA code
  const TRACKING_ID = `${process.env.REACT_APP_GA_CODE_PWA}`; // OUR_TRACKING_ID
  useEffect(async() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(window.location.pathname);
  }, [TRACKING_ID]);

  const [isLoading, setLoading] = useState(true);
  let location = useLocation().search;
  const order_id = new URLSearchParams(location).get("order_id");
  const product_id = new URLSearchParams(location).get("product_id");
  const lang = new URLSearchParams(location).get("lang");
  const platform_id = new URLSearchParams(location).get("platform_id");
  const [orderData, setOrderData] = useState([]);
  const [planType, setPlanType] = useState("");
  const [txtData, setTextData] = useState({});
  useEffect(() => {
   

      //language
      language5(lang).then((res) => {
        //set Lang conditionally
        // setDefaultTextData(res?.default);
        setTextData(res?.secondLang ? res?.secondLang : res?.default);
      });
    
  }, [lang]);
  // console.log("order success::", orderData);
  console.log(planType);
  return (
    <>
        <>
            <Subscriptionsuccess
              order_id={order_id}
              orderData={orderData}
              platform_id={platform_id}
              product_id={product_id}
              txtData={txtData}
              lang={lang}
            />
        </>
   
    </>
  );
}
