import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useImmerReducer } from "use-immer";

// Components
import Header from "../subpages/Header";
import TamashaHeader from "../subpages/TamashaHeader";
// import Sidebar from "../subpages/Plans/Sidebar";
import TamashaSlider from "../subpages/Plans/SwiperTamasha";
import TamashasPlan from "../subpages/Plans/tamasha_Plans";
import Features from "../subpages/Plans/Features";
import TamashaFeatures from "../subpages/Plans/TamashaFeatures";
import Footer from "../subpages/Footer";

//constants
import { SUCCESS, defaultPlans } from "../../../constants";

import { ampRabbitMQPageEvent, getParam } from "../../utils";
import { plansReducer } from "../../utils/reducers";
import {
  plans,
  language,
  language2,
  language3,
  logData,
  checkFreeTrialStatus,
} from "../../utils/services";
import { freeTrialPlanData } from "../../../constants";

export function TamashaPlan() {
  let history = useHistory();
  const location = useLocation().search;
  const product_id = getParam(location, "product_id");
  const platform_id = getParam(location, "platform_id");
  const country = getParam(location, "country");
  const lang = getParam(location, "lang");

  const couponcode = getParam(location, "couponcode");
  const plan_id = getParam(location, "plan_id");
  const identity = getParam(location, "identity");
  const plan_type = getParam(location, "plan_type");
  const hardware_id = getParam(location, "hardware_id");
  // const utm_source = getParam(location, "utm_source");
  const source = getParam(location, "source");
  const aff_id = getParam(location, "aff_id");

  const hostname = window.location.host;
  const pathname = useLocation().pathname;
  const urlparams = location.replace("?", "");
  const [plansData, setPlansData] = useImmerReducer(plansReducer, defaultPlans);
  const [freeTrialData, setFreeTrialData] = useState({});
  const [plansDataCopy, setPlansDataCopy] = useState({});

  const updateMultipleFormData = (obj) => {
    setPlansData({ type: "updateMultipleFormData", payload: obj });
  };

  // console.log("free trial desktop plan page");
  // console.log("free trial config data", freeTrialPlanData);
  useEffect(() => {
    //free trial
    if (freeTrialPlanData?.isActive === true) {
      checkFreeTrialStatus({ product_id, country, identity }).then((res) => {
        if (res?.status === SUCCESS) {
          // console.log("freetrial success!", res);
          if (
            res?.data?.free_trial_status === false &&
            freeTrialPlanData?.country === country &&
            freeTrialPlanData?.product_id === product_id
          ) {
            let obj = { ...freeTrialPlanData, status: false };
            setFreeTrialData(obj);
            //localStorage.setItem('user_freetrial_web',JSON.stringify(obj));
          } else {
            //setFreeTrialData({});
            let obj = { ...freeTrialPlanData, status: true };
            setFreeTrialData(obj);
          }
        }
      });
    } else {
      let obj = { ...freeTrialPlanData, status: true };
      setFreeTrialData(obj);
    }
  }, [country, identity, product_id]);

  // console.log("freeTrialData::", freeTrialData);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getPlans = (freeTrialData) => {
    plans({ product_id, platform_id, country, identity }).then((res) => {
      if (res.status === SUCCESS) {
        // console.log(res.data);
        // console.log(res.data.sliderInfo, 'sliderInfo');
        if (freeTrialData?.status === false) {
          //free trial plan amt
          const rowToChange = res?.data?.plans?.findIndex((item) => {
            return item.plan_id === Number(freeTrialData?.plan_id);
          });
          res.data.plans[rowToChange].plan_price = freeTrialData?.plan_amt;
          res.data.plans[rowToChange].is_recommended = true;
          res.data.plans[rowToChange].freeTrialPlanRecText =
            freeTrialData?.freeTrialPlanRecText;
        }
        setPlansData({
          type: "plansData",
          payload: res?.data,
        });
        //setPlansCopy
        setPlansDataCopy(res?.data);
        //default plan id set
        setPlansData({
          type: "updateMultipleFormData",
          payload: { plan_id: 10, identity: identity },
        });
      } else {
        history.push("/404");
      }
    });

    language3(lang).then((lan) => {
      console.log('language',lan);
      //set default lang for lang convert
      setPlansData({
        type: "defaultlang",
        payload: lan?.default,
      });
      setPlansData({
        type: "language",
        payload: lan[lan?.secondLang ? "secondLang" : "default"],
      });
    });

    const lgData = {
      url: hostname + pathname,
      params: location,
      couponcode: couponcode,
      plan_id: plan_id,
      product_id: product_id,
      platform_id: platform_id,
      country: country,
      identity: identity,
      plan_type: plan_type,
      hardware_id: hardware_id,
    };
    logData(lgData).then(() => {});

    /* amplitude with rabbit */
    ampRabbitMQPageEvent({
      identity: identity,
      "Page Type": "Plan Page",
      Source: source ? source : "N/A",
      "Page Language": lang ? lang : "en",
      "Affiliate ID": aff_id ? aff_id : "",
      product_id: product_id ? product_id : "",
      platform_id: platform_id ? platform_id : "",
    });
  };

  useEffect(() => {
    if (Object.keys(freeTrialData)?.length > 0) {
      getPlans(freeTrialData);
    }
  }, [freeTrialData]);

  const applyCouponCode = (obj) => {
    setPlansData({
      type: "applyCouponCode",
      payload: { data: { ...plansData?.data, urlparams }, res: obj },
    });
  };
  const amplitudeOnBack = {
    identity: identity,
    eventName: "Btn_Back_Plan",
    product_id: product_id,
    platform_id: platform_id,
  };

  return (
    <div id="wrapper">
      <TamashaHeader
        plansData={plansData}
        amplitudeOnBack={amplitudeOnBack}
        updateMultipleFormData={updateMultipleFormData}
      />
      {/* <!--Content Area Start--> */}
      <div id="content">
        <div className="main-contentBox-wrapper">
          {/* <Sidebar plansData={plansData} /> */}
          <div
            className={
              plansData?.formData?.sideBarMinimize
                ? "main-area-container noadd_right for-left-padding"
                : "main-area-container noadd_right"
            }
          >
            <div className="inner-mid-area" id="inner-mid-area">
              <div className="plan-section-1">
                <TamashaSlider language={plansData?.language} />
                <TamashasPlan
                  plansData={plansData}
                  plansDataCopy={plansDataCopy}
                  setPlansData={setPlansData}
                  updateMultipleFormData={updateMultipleFormData}
                  applyCouponCode={applyCouponCode}
                  identity={identity}
                  product_id={product_id}
                  platform_id={platform_id}
                />
              </div>
              <TamashaFeatures plansData={plansData} />
              {/* <Features plansData={plansData} /> */}
            </div>
            <Footer lang={plansData?.language} />
          </div>
        </div>
      </div>
      {/* <!--Content Area End--> */}
    </div>
  );
}
